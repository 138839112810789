import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import { PageLayout } from '../components/PageLayout'
import { CardStatus } from '../components/Card'
import { Surface } from '../components/Surface'
import { Button } from '../components/Button'
import { OperatingAgreementVersion } from '../components/OperatingAgreementVersion'
import { OperatingAgreementSideCard } from '../components/OperatingAgreementSideCard'
import { Redirect } from 'react-router-dom'
import { Start } from './Start'
import { Checkbox } from '../components/Checkbox'
import { Body } from '../components/Typography/Body'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5em;
  margin-bottom: 2.5em;
  @media (max-width: 728px) {
    flex-direction: column;
  }
`
const Footer = styled.div`
  display: flex;
`
const AgreementDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: white;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border-radius: 0.5em;
  gap: 1em;
  @media (max-width: 728px) {
    flex-direction: column;
  }
`
const AmendementDetails = styled.div`
  @media (min-width: 728px) {
    width: 35%;
  }
  background-color: #f2f7ff;
  border: 1px solid rgba(0, 94, 255, 0.25);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
`
const PreviewCard = styled.div`
  background-color: white;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 0.5em;
  box-shadow: 7px 6px 0px 0 #e9f1ff;
  margin-top: 1.5em;
  margin-left: 0.75em;
  margin-bottom: 37px;
  @media (max-width: 728px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`
const MemberDetails = styled.div``
const CornerImage = styled.img`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 8.5em;
`
const AmendmentImage = styled.img`
  padding-top: 2.3125em;
  padding-left: 1.5em;
  height: 4.1875em;
  width: 3.625em;
`
const Heading = styled.text`
  font-size: 1.25em;
  line-height: 1.5em;
  color: #444c66;
  font-weight: bold;
  padding-top: 1em;
  padding-left: 30px;
`
const AmendmentDesc = styled.text`
  padding-left: 30px;
  padding-right: 1.5em;
  font-size: 1em;
  line-height: 150%;
  color: #757a89;
  padding-top: 0.625em;
  margin-bottom: 27px;
`

const SignersWrapper = styled.div`
  display: flex;
  padding: 0.75em 2.5em 0px 30px;
  align-items: center;
`
const ButtonWrap = styled.div`
  display: flex;
  gap: 1em;
`
const SignersScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 102px;
  overflow: auto;
`
const ModalWrapper = styled.div<{ open?: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: ${props => (props.open ? 'flex' : 'none')};
`

const DownloadButton = styled.div`
  margin: 2.25em 0px 2em 30px;
  align-self: center;
  text-transform: uppercase;
  background: white;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);
  border: 1px solid #22272e;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 11px 17px;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--primary);
  white-space: nowrap;
  -webkit-transition: All 0.2s;
  transition: All 0.2s;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  color: var(--primary);
`
const selectedAgreementVersions = [
  {
    instance: '1',
    lastUpdated: 'Apr 25, 2021',
    status: CardStatus.agreementSigned,
    pendingFrom: 0,
    state: 'Delaware',
    llcName: 'StartGlobal',
    llcAddress: 'California',
    zipCode: '656565',
    city: 'walnut',
    oaMemberDTOList: [
      {
        id: 1,
        firstName: 'Jonnathan',
        lastName: 'Doe',
        email: 'jonanthan@abcInc.co',
        status: CardStatus.agreementSigned,
      },
      // { id:2, firstName: 'Anandhu',lastName:'Augustin', email: 'anandhu@startglobal.co', status: CardStatus.agreementSigned },
      // { id:3, firstName: 'Sandeep',lastName:'Suresh', email: 'sandeep@startglobal.co', status: CardStatus.agreementSigned }
    ],
  },
  // {
  //     instance: '2',
  //     lastUpdated: 'Dec 25, 2021',
  //     status: CardStatus.agreementPending,
  //     pendingFrom: 1,
  //     state:'Delaware',
  //     llcName:'StartGlobal',
  //     llcAddress:'California',
  //     zipCode:'656565',
  //     city:'walnut',
  //     oaMemberDTOList: [{ id:1, firstName: 'Sanjay',lastName:'Nediyara', email: 'sanjay@startglobal.co', status: CardStatus.agreementSigned },
  //     { id:2, firstName: 'Anandhu',lastName:'Augustin', email: 'anandhu@startglobal.co', status: CardStatus.agreementSigned },
  //     { id:3, firstName: 'Sandeep',lastName:'Suresh', email: 'sandeep@startglobal.co', status: CardStatus.agreementPending }]
  // }
]

const PreviewClose = styled.img`
  border-radius: 17px;
  right: 0px;
  top: 19px;
  position: absolute;
  cursor: pointer;
  background: #cfd5de;
  padding: 5px;
`

export const OperatingAgreementDetail = () => {
  const [versionDetail, setVersionDetail] = useState<any>({})
  const [oaAgreements, setOaAgreements] = useState<any>([])
  const [reload, setReload] = useState(true)
  const [editAgreement, setEditAgreement] = useState(false)
  const [editEnabled, setEditEnabled] = useState(true)
  const [openType, setOpen] = useState(1)
  const [data, setData] = useState<any>({})
  const [reloadData, setReloadData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(false)
  const [url, setUrl] = useState()
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationStatus, setConfirmationStatus] = useState(false)

  useEffect(() => {
    setVersionDetail(selectedAgreementVersions[0])
    setOaAgreements(selectedAgreementVersions)
    setData(selectedAgreementVersions[0])
  }, [reload])

  const handleAccordianChange = (type: number, version: any) => {
    setVersionDetail(version)
    if (type === openType) {
      setOpen(-1)
    } else {
      setOpen(type)
    }
    if (oaAgreements != null && type == oaAgreements.length) {
      setEditEnabled(true)
    } else {
      setEditEnabled(false)
    }
  }

  const previewDocument = (version: any) => {
    // api.getOAPreviewDocUrl(version.instance,version.documentId,)
    //  .then( (response) => {
    //     const data = response.payload;
    //     console.log(data);
    //     if(data) {
    //         setUrl(data.url);
    //         setPreview(true)
    //     }
    // }).finally( () => setLoading(false));
  }
  const downLoadDocument = (version: any) => {
    // api.getOAPreviewDocUrl(version.instance,version.documentId,)
    //  .then( (response) => {
    //     const data = response.payload;
    //     console.log(data);
    //     if(data) {
    //         setUrl(data.url);
    //         window.open(data.url,'_blank');
    //     }
    // }).finally( () => setLoading(false));
  }
  function editAgreementDetails() {
    setEditAgreement(true)
  }
  const getDisplayableDate = (dateStr: string) => {
    const d = new Date(dateStr) //yyyy-mm-dd
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
  }
  function onLoading(loading: boolean) {
    setLoading(true)
  }

  if (loading) {
    // return <Loader/>;
  }
  function getConfirmationPreview(status: boolean) {
    setConfirmationOpen(status)
  }
  function getConfirmationStatus(status: boolean) {
    setConfirmationStatus(status)
    setConfirmationOpen(false)
  }
  function closePreview(status: boolean) {
    setConfirmationOpen(false)
  }

  return (
    <>
      {preview && (
        <div style={{ paddingTop: '50px', position: 'relative' }}>
          <>
            <PreviewClose src="/icons/close.svg" onClick={() => setPreview(false)} alt="" />
            <iframe style={{ width: '100%', height: '88vh' }} src={url} />
          </>
        </div>
      )}

      {oaAgreements != null && oaAgreements.length != 0 && !preview && (
        <PageLayout title={'Operating agreements' + ' / ' + 'LLC Operating Agreement'}>
          <Wrapper>
            <Header>
              {versionDetail != null && (
                <>
                  <AgreementDetails>
                    <PreviewCard>
                      <Surface
                        style={{
                          borderRadius: 8,
                          position: 'relative',
                          marginBottom: '0px',
                          maxWidth: '42vh',
                          paddingBottom: '1em',
                        }}
                      >
                        <CornerImage src="/images/payment-corner.svg" alt="" />
                        <div
                          style={{
                            padding: '2.5em 2em 0px 2em',
                            fontSize: '1.125em',
                            color: 'var(--independence)',
                            textAlign: 'center',
                            lineHeight: '1.5em',
                            fontWeight: 'bold',
                          }}
                        >
                          {'LLC Operating Agreement'}
                        </div>
                        <div
                          style={{
                            padding: '1em 65px 0px 65px',
                            fontSize: '1em',
                            color: 'var(--text-secondary)',
                            textAlign: 'center',
                            lineHeight: '1.5em',
                          }}
                        >
                          For StartGlobal LLC
                        </div>
                        <div
                          style={{
                            margin: '2.75em 55px 0px 55px',
                            borderRadius: '2px',
                            background: '#D8D8D8',
                            borderBottom: '2px solid #D8D8D8',
                          }}
                        ></div>
                        <div
                          style={{
                            margin: '0.75em 4.875em 0px 4.875em',
                            borderRadius: '2px',
                            background: '#D8D8D8',
                            borderBottom: '2px solid #D8D8D8',
                          }}
                        ></div>
                        <div
                          style={{
                            padding: '2em 4.875em 0px 4.875em',
                            fontSize: '1em',
                            color: 'var(--text-secondary)',
                            textAlign: 'center',
                            lineHeight: '1.5em',
                          }}
                        >
                          Version {versionDetail.instance}
                        </div>
                        {/* <div style={{ padding: '0.625em 2.75em 1.5em 2.75em', fontSize: '1em', color: 'var(--primary)', textAlign: 'center', lineHeight: '1.5em', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => previewDocument(versionDetail)} >{LanguageUtil.get("preview-doc")}</div> */}
                        {/* <a style={{ padding: '0.625em 2.75em 1.5em 2.75em', fontSize: '1em', color: 'var(--primary)', textAlign: 'center', lineHeight: '1.5em', fontWeight: 'bold', cursor: 'pointer' }} href={url} onClick={() => previewDocument(versionDetail)}>{LanguageUtil.get("preview-doc")}</a> */}
                      </Surface>
                    </PreviewCard>
                    <MemberDetails>
                      <div
                        style={{
                          padding: '2.5em 25px 0px 30px',
                          fontSize: '19px',
                          color: 'var(--independence)',
                          lineHeight: '1.5em',
                          fontWeight: 'bold',
                        }}
                      >
                        LLC Operating Agreement
                      </div>
                      <div
                        style={{
                          padding: '0.75em 25px 0px 30px',
                          fontSize: '1em',
                          color: 'var(--text-secondary)',
                          lineHeight: '1.5em',
                        }}
                      >
                        {'Last changed on ' + getDisplayableDate(versionDetail.lastUpdated)}
                      </div>
                      <div
                        style={{
                          padding: '2.5em 25px 0px 30px',
                          fontSize: '0.8125rem',
                          color: 'var(--text-secondary)',
                          lineHeight: '15px',
                          letterSpacing: '1px',
                        }}
                      >
                        Signers
                      </div>
                      <SignersScroll>
                        {versionDetail.oaMemberDTOList.map(
                          (item: { firstName: string; lastName: string; status: CardStatus }) => (
                            <SignersWrapper>
                              <div
                                style={{
                                  fontSize: '1em',
                                  color: 'var(--independence)',
                                  lineHeight: '19px',
                                  fontWeight: 'normal',
                                  width: '60%',
                                }}
                              >
                                {item.firstName + ' ' + item.lastName}
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                                {item.status === CardStatus.agreementSigned && (
                                  <>
                                    <img style={{ width: '1em' }} src="/icons/done.svg" alt="" />
                                    <text
                                      style={{
                                        fontSize: '0.8125rem',
                                        color: 'var(--text-secondary)',
                                        lineHeight: '15px',
                                      }}
                                    >
                                      Signed
                                    </text>
                                  </>
                                )}
                                {item.status === CardStatus.agreementPending && (
                                  <>
                                    <img
                                      style={{ width: '1em' }}
                                      src="/icons/sign-pending.svg"
                                      alt=""
                                    />
                                    <text
                                      style={{
                                        fontSize: '0.8125rem',
                                        color: 'var(--text-secondary)',
                                        lineHeight: '15px',
                                      }}
                                    >
                                      Pending
                                    </text>
                                  </>
                                )}
                              </div>
                            </SignersWrapper>
                          )
                        )}
                      </SignersScroll>
                      <ButtonWrap>
                        <DownloadButton onClick={() => downLoadDocument(versionDetail)}>
                          Download
                        </DownloadButton>
                        <Button
                          disabled={editEnabled}
                          style={{
                            margin: '2.25em 1.125em 2em 0px',
                            alignSelf: 'center',
                            textTransform: 'uppercase',
                          }}
                        >
                          Sign Now
                        </Button>
                      </ButtonWrap>
                    </MemberDetails>
                  </AgreementDetails>
                  <AmendementDetails>
                    <AmendmentImage src="/images/amendment.svg" alt="" />
                    <Heading>Amendments</Heading>
                    <AmendmentDesc>
                      In order to make amendments to the Operating agreement, all members have to
                      consent to the changes that are proposed and have to resign the agreement.
                    </AmendmentDesc>
                    {/* <Button disabled={!editEnabled} style={{ margin: '0px 2em', width: '86%', alignSelf: 'center', textTransform: 'uppercase' }} onClick={editAgreementDetails}>{LanguageUtil.get("edit-operating-agreement")}</Button> */}
                  </AmendementDetails>
                </>
              )}
            </Header>
            <div
              style={{
                fontSize: '1.25em',
                color: 'var(--independence)',
                lineHeight: '1.5em',
                fontWeight: 'bold',
              }}
            >
              Versions
            </div>
            <div
              style={{
                fontSize: '1em',
                color: 'var(--text-secondary)',
                lineHeight: '19px',
                fontWeight: 'normal',
                marginTop: '0.5em',
                marginBottom: '30px',
              }}
            >
              History of all the changes made to this document
            </div>
            {oaAgreements != null &&
              oaAgreements.map(
                (item: {
                  instance: string
                  lastUpdated: string
                  status: CardStatus
                  pendingFrom: Number
                  oaMemberDTOList: {
                    firstName: string
                    lastName: string
                    email: string
                    status: CardStatus
                  }[]
                }) => (
                  <OperatingAgreementVersion
                    items={item}
                    onClick={() => handleAccordianChange(Number(item.instance), item)}
                    openType={openType}
                    currentStatus={data.status}
                    data={data}
                    reload={() => setReloadData(!reloadData)}
                    id={Number(item.instance)}
                  />
                )
              )}
            <OperatingAgreementSideCard
              open={editAgreement}
              selectedAgreement={versionDetail}
              close={() => {
                setEditAgreement(false)
                setEditEnabled(true)
                setReload(!reload)
              }}
              editable={true}
              confirmationSuccess={confirmationStatus}
              createMode={false}
            />
          </Wrapper>
          <ModalWrapper open={confirmationOpen}>
            <ConfirmationPreview confirmationStatus={getConfirmationStatus} close={closePreview} />
          </ModalWrapper>
        </PageLayout>
      )}
    </>
  )
}
interface ConfirmationPreviewProps {
  close: (close: boolean) => void
  confirmationStatus: (confirmationStatus: boolean) => void
}

const Text = styled(Body)`
  margin-left: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 17px;
  color: #757a89;
`
const PreviewWrapper = styled.div`
  background: #ffffff;
  box-shadow: 4px 31px 32px rgb(0 0 0 / 16%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 32px 36px;
  gap: 2em;
  width: 25%;
`
const AgreementWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
}
`
const CancelBtn = styled.div`
  background: #ffffff;
  border: 1px solid #d4ddeb;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #757a89;
  padding: 13px 20px;
`
const RequestBtn = styled.div`
  background: #22272e;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 13px 20px;
`
export const ConfirmationPreview = (props: ConfirmationPreviewProps) => {
  const [clicked, setClicked] = useState(false)
  const selectCard = () => {
    setClicked(!clicked)
  }
  function closePreview() {
    props.close(false)
  }
  function confirmRequest() {
    props.confirmationStatus(true)
  }
  return (
    <PreviewWrapper>
      <img style={{ width: '50px' }} src="images/pencil_edit.svg" alt="" />
      <AgreementWrap>
        <text
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            lineHeight: '24px',
            color: '#444C66',
          }}
        >
          Agreement changes
        </text>
        <text
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1rem',
            lineHeight: '24px',
            color: '#757A89',
          }}
        >
          A new version of the Operating agreement with the proposed changes will be sent to ALL
          other members for signatures
        </text>
      </AgreementWrap>
      <div style={{ display: 'flex' }}>
        <Checkbox active={clicked} onClick={selectCard} />
        <Text>Also attach the pdf of agreement</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1em' }}>
        <CancelBtn onClick={closePreview}>CANCEL</CancelBtn>
        <RequestBtn onClick={confirmRequest}>Request signatures</RequestBtn>
      </div>
    </PreviewWrapper>
  )
}
