import React from 'react'
import styled from '@emotion/styled'
import { Input } from '../Input'
import { Surface } from '../Surface'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  gap: 2em;
  flex-direction: column;
`
interface OperatingAgreementProps {}
const PreviewCard = styled.div`
  @media (max-width: 728px) {
    background-color: var(--white);
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 0.5em;
    box-shadow: 11px 11px 0px 0 #ffffff14;
    margin-left: 1.5em;
    margin-bottom: 20px;
    width: 85%;
  }
  @media (min-width: 728px) {
    background-color: var(--white);
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 0.5em;
    box-shadow: 11px 11px 0px 0 #ffffff14;
    margin-top: 1.5em;
    margin-left: 0.75em;
    height: 100%;
    position: relative;
    width: 100%;
    top: -50px;
  }
`
const CornerImage = styled.img`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 8.5em;
`
const Agreement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  gap: 1em;
  padding: 40px 0px;
`
const WrapperContent = styled.div`
  background: #22272e;
  border-radius: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 32px 32px;
  gap: 0em;
  flex-direction: column;
`

const AgreementTitle = styled.div`
  font-size: 24px;
  line-height: 130%;
  font-weight: 600;
  color: white;
`

const AgreementDesc = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #e9f1ff;
`

const Button = styled.div`
background: white;
border-radius: 3px;
color: #22272e;
padding: 11px 17px;
text-align: center;
width: -webkit-fill-available;
cursor: pointer;
text-transform: uppercase;
font-size: 16px;
line-height: 19px;
text-align: center;
letter-spacing: 1.5px;
font-weight: 600;
}
`
const KeyHighLights = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  gap: 1em;
`

const ConsentWrap = styled.div`
  background: #e9f1ff;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #444c66;
  padding: 1em;
`
export const OperatingAgreement = (props: OperatingAgreementProps) => (
  <Wrapper>
    <WrapperContent>
      <div style={{ display: 'flex' }}>
        <Agreement>
          <AgreementTitle>Your operating agreement is ready</AgreementTitle>
          <AgreementDesc>
            Get your Operating Agreement signed by all the members and save the signed copy on your
            secure account.
          </AgreementDesc>
        </Agreement>
        <PreviewCard>
          <Surface
            style={{
              borderRadius: 8,
              position: 'relative',
              marginBottom: '0px',
              minHeight: '44vh',
            }}
          >
            <CornerImage src="/images/payment-corner.svg" alt="" />
            <div
              style={{
                padding: '2.5em 2em 0px 2em',
                fontSize: '1.125em',
                color: 'var(--independence)',
                textAlign: 'center',
                lineHeight: '1.5em',
                fontWeight: 'bold',
              }}
            >
              LLC Operating Agreement
            </div>
            <div
              style={{
                padding: '1em 65px 0px 65px',
                fontSize: '1em',
                color: 'var(--text-secondary)',
                textAlign: 'center',
                lineHeight: '1.5em',
              }}
            >
              For StartGlobal LLC
            </div>
            <div
              style={{
                margin: '1em 55px 0px 55px',
                borderRadius: '2px',
                background: '#D8D8D8',
                borderBottom: '2px solid #D8D8D8',
              }}
            ></div>
            <div
              style={{
                margin: '0.75em 4.875em 0px 4.875em',
                borderRadius: '2px',
                background: '#D8D8D8',
                borderBottom: '2px solid #D8D8D8',
              }}
            ></div>
            {/* <div style={{ padding: '0.625em 2.75em 1.5em 2.75em', fontSize: '1em', color: 'var(--primary)', textAlign: 'center', lineHeight: '1.5em', fontWeight: 'bold' ,cursor:'pointer'}}>{LanguageUtil.get("preview-doc")}</div> */}
          </Surface>
        </PreviewCard>
      </div>
      {/* <div  style={{display:'flex',flexDirection:'column',gap:'1em',width:'60%'}}>
      <Input
      type="text"
      style={{background: '#0357E7', border: '1px solid rgba(255, 255, 255, 0.24)', borderRadius: '3px', color: 'white'}}
                          fullWidth
                          formStyle
                          />
                          <Input
      type="password"
      style={{background: '#0357E7', border: '1px solid rgba(255, 255, 255, 0.24)', borderRadius: '3px', color: 'white'}}
                          fullWidth
                          formStyle
                          />
                          <Button>create a free account</Button>
      </div> */}
      <Link
        style={{
          marginTop: '2em',
          background: '#0357e7',
          borderRadius: '3px',
          padding: '11px 17px',
          color: 'var(--white)',
          textAlign: 'center',
          cursor: 'pointer',
          fontSize: '15px',
          userSelect: 'none',
          letterSpacing: '1.31px',
          position: 'relative',
          textTransform: 'uppercase',
          border: '1px solid #ffffff3d',
          fontWeight: 'bold',
        }}
        to="/legal/operating-agreement-detail"
      >
        {'View operating agreement'}
      </Link>
    </WrapperContent>
    {/* <KeyHighLights>
    <img src="/icons/star.svg" alt="" />
    <label style={{fontSize: '1.5em', color: 'var(--independence)',fontWeight:'bold',lineHeight: '100%'}}>Key Highlights</label>
    </KeyHighLights>
    <ConsentWrap>
     Amending Operating agreement can be made only by the written consent of the
      <span style={{color:'var(--primary)',fontWeight:'bold'}}>Board and the members </span>
    </ConsentWrap> */}
  </Wrapper>
)
