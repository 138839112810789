/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import anime from 'animejs'
import { css } from '@emotion/core'
import { Helmet } from 'react-helmet'
import styled from '../../lib/styled'
import { Heading, HeadingSize } from '../Typography/Heading'
import { Body, BodySize } from '../Typography/Body'
import { CardButton, CardButtonType } from '../CardButton'
import { Icon, IconType } from '../Icon'
import { StartFormLayout } from '../StartFormLayout'
import { StartSidebar } from '../StartSidebar'
import { useStartForm, CompanyType } from '../StartFormProvider'

const sidebarId = 'choose-company-type-sidebar'
const contentId = 'choose-company-type-content'
const signImgId = 'choose-company-type-sign-image'
const logoId = 'choose-company-type-logo'
const sidebarContentId = 'choose-company-type-sidebar-content'
const circleId = 'choose-company-type-circle'

const LogoWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 167px;
`
const Circle = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  background-color: ${props => props.theme.color.white};
`
const SignImage = styled.img`
  position: absolute;
  bottom: 43px;
  right: 0;

  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
`
const HelpPlayButton = styled.span`
  display: inline-block;
  animation: pulse 2s infinite;
  border-radius: 50px;
  padding: 9px 20px 6px 20px;
  border: 2px solid ${props => props.theme.color.primary};
  color: ${props => props.theme.color.primary};
  position: absolute;
  top: 54px;

  a {
    display: inline-flex;
    align-items: center;
    color: ${props => props.theme.color.primary};
    :focus {
      color: ${props => props.theme.color.primary};
    }
  }

  img + span {
    margin-left: 15px;
  }

  ${({
    theme: {
      color: { primary00, primary04 },
    },
  }) => css`
    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 ${primary04};
      }
      70% {
        -webkit-box-shadow: 0 0 0 10px ${primary00};
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 ${primary00};
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 ${primary04};
        box-shadow: 0 0 0 0 ${primary04};
      }
      70% {
        -moz-box-shadow: 0 0 0 10px ${primary00};
        box-shadow: 0 0 0 10px ${primary00};
      }
      100% {
        -moz-box-shadow: 0 0 0 0 ${primary00};
        box-shadow: 0 0 0 0 ${primary00};
      }
    }
  `}
`

export const ChooseCompanyType = (props: { onSubmit: (state: CompanyType) => void }) => {
  const startForm = useStartForm()
  const onSubmit = (type: CompanyType) => {
    startForm.setCompanyType(type)
    anime
      .timeline()
      .add({
        targets: `#${circleId}`,
        height: ['0vh', '250vh'],
        width: ['0vh', '250vh'],
        left: ['0vh', '-125vh'],
        bottom: ['0vh', '-125vh'],
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add(
        {
          targets: `#${contentId}`,
          opacity: [1, 0],
          duration: 800,
          easing: 'easeOutQuint',
        },
        100
      )
    setTimeout(() => props.onSubmit(type), 1000)
  }

  useEffect(() => {
    anime
      .timeline()
      .add(
        {
          targets: `#${sidebarId}`,
          translateX: [-500, 0],
          duration: 800,
          easing: 'easeOutQuint',
        },
        500
      )
      .add(
        {
          targets: `#${signImgId}`,
          scale: [0, 1],
          duration: 600,
          easing: 'easeOutQuint',
        },
        '-=500'
      )
      .add(
        {
          targets: `#${logoId}, #${sidebarContentId}`,
          opacity: [0, 1],
          easing: 'easeOutQuart',
          delay: anime.stagger(150),
          duration: 500,
        },
        '-=500'
      )
      .add(
        {
          targets: `#${contentId}`,
          translateY: [50, 0],
          opacity: [0, 1],
          duration: 800,
          easing: 'easeOutQuart',
        },
        '-=200'
      )
    return () => {}
  }, [])

  return (
    <>
      <Helmet>
        <script src="https://fast.wistia.com/embed/medias/oh5flb89qp.jsonp" async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <StartFormLayout
        title="Choose the type of company"
        description="To get started tell us what type of company you are going to incorporate, LLC or Corporation."
        contentId={contentId}
        sidebar={
          <StartSidebar
            id={sidebarId}
            style={{ backgroundColor: 'var(--primary)', overflow: 'hidden' }}
          >
            <LogoWrapper id={logoId}>
              <img src="/images/logo-truic.png" alt="Start Global" />
            </LogoWrapper>
            <div id={sidebarContentId}>
              <Heading light size={HeadingSize.xxl} style={{ marginBottom: 48 }}>
                A few clicks away from creating your company.
              </Heading>
              <Body size={BodySize.xl} color="var(--text-light-secondary)">
                Start your company in minutes. Save time and money.
              </Body>
            </div>
            <SignImage id={signImgId} src="/images/company-sign.svg" alt="" />
            <Circle id={circleId} />
          </StartSidebar>
        }
      >
        <HelpPlayButton className="wistia_embed wistia_async_oh5flb89qp popover=true popoverContent=link">
          <a href="#">
            <Icon type={IconType.play} />
            <span>Watch help video</span>
          </a>
        </HelpPlayButton>
        <CardButton
          type={CardButtonType.llc}
          onClick={() => onSubmit(CardButtonType.llc as CompanyType)}
        />
        <CardButton
          type={CardButtonType.corporation}
          onClick={() => onSubmit(CardButtonType.corporation as CompanyType)}
        />
      </StartFormLayout>
    </>
  )
}
